import propTypes from "prop-types"
import { Button } from "@mantine/core"

function Confirm({ onConfirm, onReject, text, isLoading }) {
  return (
    <div className="modal-confirm">
      <div>{text}</div>
      <div className="btn-group">
        <Button type="button" onClick={onConfirm} className="btn btn-primary" loading={isLoading}>
          Tasdiqlash
        </Button>
        <span>yoki</span>
        <button type="button" onClick={onReject} className="btn btn-cencel">
          Bekor qilish
        </button>
      </div>
    </div>
  )
}

export default Confirm

Confirm.propTypes = {
  onConfirm: propTypes.func,
  onReject: propTypes.func,
  text: propTypes.string,
}

Confirm.defaultProps = {
  onConfirm: () => {},
  onReject: () => {},
  text: "Delete the selected records?",
}
