import { useEffect, Suspense, useState } from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { globalState, queryClient } from "config"
import { Spinner } from "components"
import { useRequest } from "hooks"
import useClearCache from "hooks/useClearCache"

import { get } from "lodash"
import { Emitter, helpers } from "services"

import AdminRoutes from "./Admin"
import GuestRoutes from "./Guest"

function Routes() {
  useClearCache()
  const [isFetched, setIsFetched] = useState(true)
  const state = useQuery({
    queryKey: ["globalState"],
    queryFn: () => globalState,
    initialData: () => globalState,
    staleTime: Infinity,
  })

  const getMe = useRequest({
    url: "profile",
    route: "",
    enabled: false,
    retry: 0,
    ttl: 1,
    cacheId: "profile",
    onSuccess: (data) => {
      try {
        sessionStorage.setItem("user", JSON.stringify(get(data, "data")))
      } catch (e) {
        //
      }
      queryClient.setQueryData(["globalState"], (old) => ({
        ...old,
        user: get(data, "data"),
        isAuth: true,
      }))
      setIsFetched(true)
    },
    onError: () => {
      queryClient.setQueryData(["globalState"], (old) => ({
        ...old,
        isAuth: false,
      }))
      GuestRoutes.navigate("/signin")
      setIsFetched(true)
    },
  })

  useEffect(() => {
    const urlParams = new URL(document.location).searchParams
    const token = urlParams.get("token")
    if (token) {
      localStorage.setItem("token", token)
    }
    Emitter.on("profile:refresh", () => {
      getMe.refetch()
      setIsFetched(false)
    })
  }, [])

  useEffect(() => {
    if (window.localStorage.getItem("token")) {
      getMe.refetch()
      setIsFetched(false)
    } else {
      getMe.remove()
      queryClient.setQueryData(["globalState"], (old) => ({
        ...old,
        isAuth: false,
      }))
    }
  }, [state.data.isAuth])

  return (
    <div className="app">
      {!isFetched ? (
        <Spinner />
      ) : (
        <Suspense fallback={<Spinner />}>
          {state.data.isAuth ? (
            <RouterProvider router={createBrowserRouter(helpers.filterRoutes(AdminRoutes))} />
          ) : (
            <RouterProvider router={GuestRoutes} />
          )}
        </Suspense>
      )}
    </div>
  )
}

export default Routes
